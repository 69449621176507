@import url(https://fonts.googleapis.com/css?family=Dancing+Script|Raleway);
body {
  font-family: 'Raleway', sans-serif !important;
}

h1 {
  font-family: 'Dancing Script', cursive !important;
}

.jumbotron {
  position: relative;
  background: #000 url(/static/media/soap-banner.29350c87.jpg) center center;
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: hidden;
}

.selected-mass-button {
  background-color: #337ab7 !important;
  border-color: #2e6da4 !important;
  color: #FFF !important;
}

.good-soap-property {
  background-color: #dff0d8 !important;
  color: #468847;
}

/* Animations */

.ingredient-enter {
  opacity: 0;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
}
.ingredient-enter.ingredient-enter-active {
  opacity: 1;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  -webkit-transition: opacity 100ms linear, -webkit-transform 200ms;
  transition: opacity 100ms linear, -webkit-transform 200ms;
  transition: transform 200ms, opacity 100ms linear;
  transition: transform 200ms, opacity 100ms linear, -webkit-transform 200ms;
}
.ingredient-leave {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}
.ingredient-leave.ingredient-leave-active {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  opacity: 0;
  -webkit-transition: opacity 100ms linear, -webkit-transform 200ms;
  transition: opacity 100ms linear, -webkit-transform 200ms;
  transition: transform 200ms, opacity 100ms linear;
  transition: transform 200ms, opacity 100ms linear, -webkit-transform 200ms;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

