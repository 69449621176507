@import url('https://fonts.googleapis.com/css?family=Dancing+Script|Raleway');

body {
  font-family: 'Raleway', sans-serif !important;
}

h1 {
  font-family: 'Dancing Script', cursive !important;
}

.jumbotron {
  position: relative;
  background: #000 url("../images/soap-banner.jpg") center center;
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: hidden;
}

.selected-mass-button {
  background-color: #337ab7 !important;
  border-color: #2e6da4 !important;
  color: #FFF !important;
}

.good-soap-property {
  background-color: #dff0d8 !important;
  color: #468847;
}

/* Animations */

.ingredient-enter {
  opacity: 0;
  transform: translateY(-60%);
}
.ingredient-enter.ingredient-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: transform 200ms, opacity 100ms linear;
}
.ingredient-leave {
  transform: translateY(0%);
}
.ingredient-leave.ingredient-leave-active {
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 200ms, opacity 100ms linear;
}
